import CTAWithImage from '../components/CTAWithImage'
import SlackCommunity from '../components/SlackCommunity'
import Footer from '../components/Footer'

import Navbar from '../components/Navbar'
 
import {
    CheckIcon
  } from '@heroicons/react/20/solid'
import FeatureSnippet from '../components/FeatureSnippet'
  

import AutomationFlow from "../assets/product/automationflow.png"
import CustomerSegments from "../assets/product/customersegments.png"
import EmailCampaigns from "../assets/product/emailcampaign.png"
import Frontpage from "../assets/product/frontpage.png"
import Subscribers from "../assets/product/subscribers.png"
import CampaignMonitor from "../assets/product/runningcampaign.png"
import ViewCampaignForUser from "../assets/product/campaignview.png"

export default function Product() {

  return (
    <>
    <Navbar/>
 
 
    <div className="bg-gray-900 pt-24 sm:pt-32 pb-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">Everything you need - powered by LLM</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Email marketing for permance teams.</p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            This is a walk-through of the most important features the product has to offer.
          </p>
        </div>
      </div>
    </div>



    <FeatureSnippet 
        direction="ltr"
        image={EmailCampaigns}
        catch="Better Quality"
        title="Email campaigns"
        subtitle="Patrick is a marketer and uses Notification Harbor. His company is expanding into a new demographic area. Patrick provides an 'intent' prompt about the new expansion and that it should include a 10% discount using the code &#xFF02;AARHUS2024&#xFF02; when creating the email campaign."
        features={[
            {
              name: 'Create email content',
              description:
                'You can create email content and adjust tonality using simple prompts.',
              icon: CheckIcon,
            },
            {
              name: 'Create email templates',
              description: 'Enter a product url and the system will automatically include images and relevant information. Even generate a template that resembles the website in according to best-practice email design.',
              icon: CheckIcon,
            },
            {
              name: 'Send now or later',
              description: 'Send the email now to one subscriber, a customer segment or save it and use it later with an automation flow.',
              icon: CheckIcon,
            },
          ]}
    />

    <FeatureSnippet 
        direction="rtl"
        image={AutomationFlow}
        catch="Simple Automation"
        title="Automation flows"
        subtitle="Patrick wants all new subscribers to get the email about the company expansion and offer a 10% discount. He creates an automation flow for this purpose"
        features={[
            {
              name: 'Simple to use',
              description: 'The automation flow builder makes the process of building automation flow very simple',
              icon: CheckIcon,
            },
            {
              name: 'New features',
              description: 'Right now automation flows can only be initialized for new subscribers. New initializers, actions, triggers and rules are continously rolling out',
              icon: CheckIcon,
            },
          ]}
    />


    <FeatureSnippet 
        direction="ltr"
        image={CampaignMonitor}
        catch="Great Overview"
        title="Campaign monitor"
        subtitle="Patricks emails are performing well. Patrick monitors who's received the different campaigns. Automatic A/B split testing powered by LLM under the hood ensures Patricks marketing content is top-tier"
        features={[
            {
              name: 'View metrics',
              description:
                'see how your email campaign is performing in real-time',
              icon: CheckIcon,
            },
            {
              name: 'View performance',
              description: 'see how your email campaign is improving since it was first sent to the first subscribers',
              icon: CheckIcon,
            },
            {
              name: 'View receivers',
              description: 'see which of your users that engaged with the email and view under-the-hood the email that was sent and targeted specifically to them',
              icon: CheckIcon,
            },
          ]}
    />

    <FeatureSnippet 
        direction="rtl"
        image={ViewCampaignForUser}
        catch="Full Transparency"
        title="View under the hood"
        subtitle="Patrick loves Notification Harbor. He studies the different versions of the email that was uniquely optimized for each subscriber. Patrick is a happy marketer"
        features={[
            {
              name: 'View email',
              description:
                'see the email template and text sent to the user and toggle between the different versions different subscribers have received',
              icon: CheckIcon,
            }
          ]}
    />


    <div className='text-center py-48 text-white'>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl mb-2">Other features</h1>
        <p className='text-md text-gray-300'>Other pages that didn't really fit into the story above - but is just as important.</p>
    </div>



    <FeatureSnippet 
        direction="rtl"
        image={Frontpage}
        catch="Great Overview"
        title="Dashboard"
        subtitle="When you've loggedin and you're setup this is your dashboard"
        features={[
            {
              name: 'Performance overview',
              description:
                'see how many subscribers you have, your avg open rate and click-through rate',
              icon: CheckIcon,
            },
            {
              name: 'Quick actions',
              description: 'Swifty navigate to the desired page and create emails, automation flows in a matter of minutes',
              icon: CheckIcon,
            }
          ]}
    />

    <FeatureSnippet 
        direction="ltr"
        image={CustomerSegments}
        catch="AI segmentation"
        title="Customer segmentation"
        subtitle="Manage who receives which emails with your customer segmentations"
        features={[
            {
              name: 'Tag based',
              description:
                'create tag based customer segmentation',
              icon: CheckIcon,
            },
            {
              name: 'AI based (out soon)',
              description: 'we automatically create customer segments based on the tags and data avaiable on the users',
              icon: CheckIcon,
            }
          ]}
    />


    <FeatureSnippet 
        direction="rtl"
        image={Subscribers}
        catch="API integration"
        title="Subscriber"
        subtitle=""
        features={[
            {
              name: 'Manage subscribers',
              description:
                'Add a single subscriber, delete and update subscribers',
              icon: CheckIcon,
            },
            {
              name: 'Import subscribers',
              description: 'Import subscribers with tags, names, birthday etc',
              icon: CheckIcon,
            },
            {
              name: 'Add subscribers',
              description: 'Add subscribers via our API',
              icon: CheckIcon,
            },
          ]}
    />


 

    
    <CTAWithImage/>
    <SlackCommunity/>
    <Footer/>

    </>
  )
}
