import { Link } from 'react-router-dom'

export default function CTAWithImage() {
    return (
      <div className="relative">

      <div className="relative isolate overflow-hidden z-10" style={{'backgroundSize': '40px 40px','backgroundPosition':'0px 10px','backgroundImage': 'radial-gradient(circle, #338b93 1px, rgba(0, 0, 0, 0) 1px)'}}>
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Boost your productivity.
              <br />
              Try the future of email marketing today.
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
              Notification Harbor - Where innovation meets efficiency in email marketing.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link to="https://dashboard.notificationharbor.com/signup" className="rounded-md bg-white px-5 py-4 text-md font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                Get started for free
              </Link>
              <Link to="/product" className="text-md font-semibold leading-6 text-white">
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
        
      </div>
      <svg
          viewBox="0 0 1024 1024"
          className="absolute -right-1/4 top-0 z-0 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          aria-hidden="true"
        >
          <circle cx={512} cy={512} r={512} fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fillOpacity="0.7" />
          <defs>
            <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
              <stop stopColor="#338b93" />
              <stop offset={1} stopColor="#4f46e5" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    )
  }
  