 
 
type feature = {
    direction:string, 
    image:string,
    catch:string,
    title:string,
    subtitle:string,
    features: any[]
}

export default function FeatureSnippet(props:feature) {
  return (
    <div className="overflow-hidden bg-gray-900 py-12 sm:py-18">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div dir={props.direction==="ltr" ? "ltr" : "rtl"} className="mx-auto grid grid-reverse  max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8  lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base text-left font-semibold leading-7 text-indigo-400">{props.catch}</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl  text-left">{props.title}</p>
              <p className="mt-6  text-left text-lg leading-8 text-gray-300">
                {props.subtitle}
              </p>
              <dl dir="ltr" className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                {props.features.map((feature) => (
                  <div key={feature.name} className="text-left relative pl-9">
                    <dt className="inline font-semibold text-white">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={props.image}
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  )
}
