import { RedocStandalone } from 'redoc';

export default function Documentation() {

  return (
    <div className='bg-white'>
      <RedocStandalone
        specUrl={process.env.REACT_APP_MODE==="development" ? "http://localhost:8080/swagger-customer.json" : "https://api.notificationharbor.com/swagger-customer.json"}
        options={{
          nativeScrollbars: true,
          theme: { colors: { primary: { main: '#000' } }},
          
        }}
      />
      

    </div>
  )
}
