import { Link } from 'react-router-dom'

const faqs = [
    {
      id: 1,
      question: "How much does Notification harbor cost?",
      answer:
        "Notification harbor is free. Yes! The platform is still getting perfected to meet all your great feedback. The core features such as email marketing and automation flows is still under development - therefore we've decided to make the tool free for now.",
    },
    {
      id: 2,
      question: "I'm already using another email marketing tool. Can i switch?",
      answer:
        "Yes. Notification harbor has an import feature. So you just have to export your subscribers from your existing tool and import your list under 'subscribers'. Notification harbor will take care of the rest and include tags, name, birthday etc..",
    },
    {
      id: 3,
      question: "How is notification harbor different from mailchimp?",
      answer:
        "In mailchimp you manually have to create all email content and email templates. With notification harbor our system creates a unique email template optimized for your campaign and tailored content for each user based on an 'intent'.",
    }
    // More questions...
  ]
  
  export default function FAQ() {
    return (
      <div className="bg-gray-900">
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
          <p className="mt-6 max-w-2xl text-base leading-7 text-gray-100">
            Have a different question and can’t find the answer you’re looking for? Reach out to our founder by{' '}
            <Link to="mailto:admin@guldstoev.com" className="font-semibold text-indigo-600 hover:text-indigo-500">
              sending us an email
            </Link>{' '}
            and we’ll get back to you as soon as we can. 
          </p>
          <div className="mt-20">
            <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
              {faqs.map((faq) => (
                <div key={faq.id}>
                  <dt className="text-base font-semibold leading-7 text-white">{faq.question}</dt>
                  <dd className="mt-2 text-base leading-7 text-gray-100">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  }
  