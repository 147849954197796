import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";

/** pages */
import Home from "./pages/Home";
import Product from "./pages/Product";
import Documentation from "./pages/Documentation";
import ContactUs from './pages/ContactUs';

function App() {
  return (
    <div className="bg-gray-900 min-h-[100vh]">
      <BrowserRouter>
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;
