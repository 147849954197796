import { BoltIcon, CloudArrowUpIcon, EnvelopeIcon, LockClosedIcon, ServerIcon, UserGroupIcon } from '@heroicons/react/20/solid'

import AutomationFlow from "../assets/product/automationflow.png"
import { Link } from 'react-router-dom'

export default function Features() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-950 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-950">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Email marketing optimized</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">We Bring Email Marketing To The Next Level</h1>
              <p className="mt-6 text-lg leading-8 text-gray-100">
                We're dedicated to leading the way for email marketing teams in the AI era and ensuring high quality email content for all email consumers with our email marketing platform. 
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-100">
              Our platform will simplify the way you make email campaigns and connect with your audience, ensuring every campaign hits the mark with precision and creativity.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={AutomationFlow}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-400 lg:max-w-lg">
              <p> </p>
              <ul className="mt-2 space-y-8 text-gray-400">
                <li className="flex gap-x-3">
                  <EnvelopeIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-100">AI Generated Email Templates </strong> provides the best-fit email template based on your prompt. Our LLM model generates an email template that suits the provided information and requirements. This can be everything from a url with product information to a description of a re-targeting campaign.
                    </span>
                </li>
                <li className="flex gap-x-3">
                  <UserGroupIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-100">Real-time Email Personalization </strong> 
                    improves engagement and conversion with highly relevant text that is uniquely tailored to the subscriber for each email campaign you run.
                    Even in automation flows the personalization is real-time and ensures your strategy is more intelligent.
                    </span>
                </li>
                <li className="flex gap-x-3">
                  <BoltIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-100">AI driven email marketing </strong>
                    allows your email marketing team to spend 60% less time on creating email templates and email text.
                  </span>
                </li>
              </ul>
              <p className="mt-2"></p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-100">Already using something else? No problem.</h2>
              <p className="mt-6">
                Are you already using another email marketing tool? You can easily import your users!
              </p>

              <p className='text-white text-lg mt-8 font-semibold'>
                <span className='text-indigo-600 text-lg mr-2'>Ready?</span> 
                <Link className=' text-white underline' to="https://dashboard.notificationharbor.com/signup">Get started for free</Link>
                <span className='mx-2'>or</span>  
                <Link to="/product" className=" leading-6 text-white ">learn more <span aria-hidden="true">→</span></Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
