/* eslint-disable no-multi-str */
import { PaperAirplaneIcon, ServerIcon, UsersIcon } from '@heroicons/react/20/solid'
import { CopyBlock,dracula } from 'react-code-blocks';

const features = [
  {
    name: 'Send 10.000 transactional emails for free each month',
    description:
      ' with our blazingly fast SMTP server infrastructure. This should cover most smaller businesses needs.',
    icon: PaperAirplaneIcon,
  },
  {
    name: 'Manage subscribers',
    description: ' via our API. You can update, edit and delete your subscribers and trigger automation flows.',
    icon: UsersIcon,
  },
  {
    name: 'Dedicated IP',
    description: ' for businesses. We have extremely high standards and processes in place ensuring optimal deliverability - but yes, we do offer dedicated IP\'s',
    icon: ServerIcon,
  }
]

export default function CodeExample(){


  return (
    <div className="overflow-hidden bg-gray-900 py-24 sm:py-32 relative">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid z-10 relative grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">All-in-one solution</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">API for all your email needs</p>
              <p className="mt-6 text-lg leading-8 text-gray-100">
                Sometimes we just need to send an email without all the fancy glimmer. We've made an API where you can send transactional emails, add and manage subscribers.

              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-white">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline text-gray-400">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
              <div
                className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                aria-hidden="true"
              />
              <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                <div className="w-screen overflow-hidden rounded-tl-xl bg-gray-900 ring-1 ring-white/10">
                  <div className="flex bg-gray-800/40 ring-1 ring-white/5">
                    <div className="-mb-px flex text-sm font-medium leading-6 text-gray-400">
                      <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                        SMTPRelayAPI.jsx
                      </div>
                      <div className="border-r border-gray-600/10 px-4 py-2">App.jsx</div>
                    </div>
                  </div>
                  <div className=" ">
                    <CopyBlock
text={"\
import axios from 'axios'; \n\
const qs = require('qs');\n\
\n\
const data = qs.stringify({ \n\
  from: 'hello@notificationharbor.com', \n\
  name: 'Notification harbor',\n\
  to: 'hello@example.com', \n\
  subject: 'Hello there!', \n\
  html: '<p>This is an email body!</p>'\n\
});\n\
\n\
const config = {\n\
  method: 'post',\n\
  url: 'https://api.notificationharbor.com/v1/smtp/send',\n\
  headers: { \n\
    'Content-Type': 'application/x-www-form-urlencoded',\n\
    'x-api-key': 'YOUR_API_KEY',\n\
  },\n\
  data: data\n\
};\n\
\n\
axios(config).then(function (response) {\n\
  console.log(JSON.stringify(response.data));\n\
}).catch(function (error) {\n\
  console.log(error);\n\
});"}
                      language={"javascript"}
                      showLineNumbers={true}
                      wrapLongLines={false}
                      theme={dracula}
                      codeBlock
                    />
                  </div>
                </div>
              </div>
              <div
                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>

        {/** art stuff */}
        <div
            className="pointer-events-none absolute -left-96 bottom-12 z-0 -translate-y-1/8 transform-gpu blur-2xl   lg:top-auto lg:translate-y-0 lg:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/500] w-[72.1875rem] bg-gradient-to-tr from-[#338b93] to-[#211990] opacity-25"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
        </div>
        {/** art stuff end */}

        
      </div>
    </div>
  )
}

  