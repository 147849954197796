import { Link } from "react-router-dom"
import slackLogo from "../assets/slack.png"

export default function SlackCommunity() {
    return (
      <div className="py-16 sm:py-24 lg:py-32 relative"  style={{'backgroundSize': '40px 40px','backgroundPosition':'20px 0px','backgroundImage': 'linear-gradient(to left, #338b93 1px, transparent 1px)'}}>
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div className="max-w-xl text-3xl font-bold tracking-tight text-white sm:text-4xl lg:col-span-7">
            <h2 className="inline sm:block lg:inline xl:block">Want product news and updates?</h2>{' '}
            <p className="inline sm:block lg:inline xl:block">Join our slack community.</p>
          </div>
          <div className="w-full max-w-md lg:col-span-5 lg:pt-2">
            
            <Link to="https://join.slack.com/t/notificationharbor/shared_invite/zt-2f3vnwbcl-ZSCgRywjS6g4XfzS28O9Mg" target="_blank">
            <button className="bg-[#4A154B] flex items-center px-8 py-5 rounded-md hover:bg-[#4A154B] transition-colors duration-300 ease-in-out">
              <img src={slackLogo} alt="Slack" className="w-6 h-6 mr-2"/>
              <span className="text-white text-lg font-bold">JOIN SLACK COMMUNITY</span>
            </button>
            </Link>

          </div>
        </div>
      </div>
    )
  }
  