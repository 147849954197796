import Features from '../components/Features'
import LogoClouds from '../components/LogoClouds'
import CTAWithImage from '../components/CTAWithImage'
import SlackCommunity from '../components/SlackCommunity'
import Footer from '../components/Footer'

import Navbar from '../components/Navbar'
import HeroElement from '../components/HeroElement'
import FAQ from '../components/FAQ'
import CodeExample from '../components/CodeExample'
 

export default function Home() {

  return (
    <>
    <Navbar/>
    <HeroElement/>

    <LogoClouds/>
    <Features/>
    <CodeExample/>
    <CTAWithImage/>
    <SlackCommunity/>
    <FAQ/>
    <Footer/>

    </>
  )
}
